<template>
	<div class="container" :class="expanded ? '' : 'not-expanded'">
		<div class="faq-toggle header" @click="expanded = !expanded">
			<h3>{{ header }}</h3>
			<font-awesome-icon icon="fa-light fa-chevron-down" size="1x" />
		</div>
		<div class="faq-content content">
			<div v-parse-links v-html="content" />
		</div>
	</div>
</template>

<script setup>
defineProps({
	header: { type: String, default: '' },
	content: { type: String, default: '' },
});

const expanded = ref(false);
</script>

<style lang="scss" scoped>
.faq-row {
	max-width: 1180px;
	margin: 20px auto 40px;
}

.faq-content {
	background: #f4f4f4;
	padding: 20px 30px;
}

.faq-toggle {
	cursor: pointer;
	background: #fff;
	transition: all 0.3s ease-in-out;
	position: relative;
	font-weight: 700;
	padding: 20px 54px 20px 30px;
	border-bottom: 2px solid #f4f0e7;
}

.faq-toggle svg {
	position: absolute;
	right: 24px;
	top: 50%;
	margin-top: -11px;
	color: #d9bd8b;
	transition: all 0.3s ease-in-out;
	font-weight: 400;
	font-size: 22px;
	transform: rotate(180deg);
}

.not-expanded .faq-toggle svg {
	transform: rotate(0deg);
}

.faq-toggle h3 {
	margin: 0;
	font-size: 24px;
}

.header {
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.container {
	display: inline-block;
	vertical-align: top;
	text-align: left;
	width: 100%;

	.content {
		transition: all 300ms ease;

		ul,
		ol {
			padding: 0 0 0 20px;
		}
	}

	&.not-expanded .content {
		transform: scaleY(0);
		transform-origin: top;
		opacity: 0;
		height: 0;
		padding: 0 20px;
	}
}
</style>
